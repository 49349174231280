import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class NotificationsComponent {
    constructor() {
        const container = $('#notifications')
        if (container.length > 0 && !$('body').hasClass('eco-mode-active')) {
            NotificationsComponent.iconCLick()
            NotificationsComponent.close()
            NotificationsComponent.swiper()

            if ($('body').hasClass('home')) {
                setTimeout(() => {
                    $('#notifications').removeClass('closed')
                }, 2000)
            }
        }
    }

    static iconCLick() {
        const $icon = $('.tools__item--notifications')
        if ($icon.length > 0) {
            $icon.on('click', () => {
                if (window.matchMedia('(max-width:1000px)').matches) {
                    $('[data-trigger="wpiris-menu-toggle"]').trigger('click')
                }
                $('#notifications').toggleClass('closed')
                return false
            })
        }
    }

    static close() {
        const $btn = $('#notifications > .close')
        if ($btn.length > 0) {
            $btn.on('click', () => {
                $('#notifications').toggleClass('closed')
                return false
            })
        }
    }

    static async swiper() {
        const slider = $('div[data-swiper="customer-notifications"]')
        if (slider.length > 0 && !$('body').hasClass('eco-mode-active')) {
            const swiperKey = 'customer-notifications'
            const slideItem = `[data-swiper="${swiperKey}"] > .notifications__item`

            const options = {
                mousewheel: {
                    forceToAxis: true,
                },
                slidesPerView: 'auto',
                spaceBetween: 20,
                autoplay: {
                    delay: 7000,
                },
                speed: 1000,
                keyboard: true,
                loop: false,
                pagination: {
                    el: '.notifications__pagination',
                    clickable: true,
                },
                freeMode: {
                    enabled: false,
                },
                freeModeFluid: true,
                passiveListeners: false,
                watchSlidesVisibility: true,
                breakpoints: {
                    1000: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                        freeMode: {
                            enabled: false,
                        },
                        freeModeFluid: false,
                    },
                },
            }

            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

            const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
        }
    }
}
