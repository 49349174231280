export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.submenu()
        NavbarComponent.accelerators()
        NavbarComponent.langs_switcher()
        NavbarComponent.advancedSearch()
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 100 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
                if (full === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else {
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
                if (full === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        const body = $('body')
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        //Open/Close menu
        $(trigger).on('click', () => {
            NavbarComponent.loadAdvancedSearch()
            $('#notifications').addClass('closed')
            body.toggleClass('menu-open')

            if (body.hasClass('menu-open')) {
                NavbarComponent.changeLogoColor('banner')

                // close ecobar
                $('body').removeClass('eco-bar-open')
                $('.eco-bar').addClass('eco-bar--hidden')
            } else {
                if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                    NavbarComponent.changeLogoColor('banner')
                } else {
                    NavbarComponent.changeLogoColor('classic')
                }
            }
        })

        //Open/Close submenu
        $(more).on('click', function () {
            $(this).next(submenu).toggleClass('submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $('.menu__wrapper .menu__item').removeClass('is-active').removeClass('has-submenu-open')
            $(submenu).removeClass('submenu-open')
        })
    }

    static submenu() {
        if (window.matchMedia('(min-width:1000px)').matches) {
            $('.menu__wrapper .menu__item').first().addClass('is-active')
        }

        $('.menu__item__link').on('click', function (e) {
            // second click (already open)
            if ($(this).parent('.menu__wrapper .menu__item').hasClass('is-active')) {
                return true
            }

            // first click (open submenu)
            $('.menu__wrapper .menu__item').removeClass('is-active').removeClass('has-submenu-open')
            $('.menu__wrapper .menu__item__submenu').removeClass('submenu-open')
            $(this)
                .parent('.menu__wrapper .menu__item')
                .addClass('is-active')
                .addClass('has-submenu-open')

            return false
        })
    }

    static accelerators() {
        $('.accelerators__item .menu__item__link').on('click', function (e) {
            const target = $(this).data('target')
            const $menu__item = $(`[data-submenu-key="${target}"`)

            $('[data-trigger="wpiris-menu-toggle"]').trigger('click')

            $('.menu__wrapper .menu__item').removeClass('is-active').removeClass('has-submenu-open')
            $('.menu__wrapper .menu__item__submenu').removeClass('submenu-open')
            $menu__item.addClass('is-active')
            $menu__item.find('.menu__item__submenu').addClass('submenu-open')

            return false
        })
    }

    static langs_switcher() {
        const current_lang = $('.lang__current')
        const list_langs = $('.lang__list')

        const current_lang_value = list_langs.find('.gt-current-lang').text()

        //Current
        $('.lang__current span').text(current_lang_value.substr(0, 2))

        //Hide submenu
        list_langs.hide()

        //Open submenu
        $('.tools__item--languages').on('click', () => {
            $(list_langs).toggleClass('lang__list--open')
            $('.lang__list--open').fadeIn(250)
            $('.lang__list:not(.lang__list--open)').fadeOut(250)
        })

        //Hide submenu on outside click
        $(window).click((e) => {
            if (e.target.className !== 'current__lang') {
                if ($(list_langs).hasClass('lang__list--open')) {
                    $(list_langs).removeClass('lang__list--open')
                    list_langs.fadeOut(250)
                }
            }
        })

        //Remove current language on list (init)
        $('.lang__list a.glink').each(function () {
            if ($(this).text() === current_lang_value) {
                $(this).hide()
            }
        })

        //Change Navbar Language on click
        $('.lang__list > a').on('click', function () {
            $('.lang__list > a').show()
            $(this).hide()
            $('.lang__current span').text(current_lang_value)
        })
    }

    static advancedSearch() {
        document.addEventListener('irisadvancedsearchissearching', () => {
            $('.menu .menu__wrapper').hide()
            $('.menu').addClass('currently-searching')
        })
        document.addEventListener('irisadvancedsearchnosearching', () => {
            $('.menu .menu__wrapper').show()
            $('.menu').removeClass('currently-searching')
        })
        $('.menu').on('scroll', () => {
            if ($('.menu').scrollTop() === 0) {
                $('.navbar').removeClass('scrollling')
            } else {
                $('.navbar').addClass('scrollling')
            }
        })
    }

    static loadAdvancedSearch() {
        const eventLoad = new Event('irisadvancedsearchload')
        document.dispatchEvent(eventLoad)
    }
}
